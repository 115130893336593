<template>
	<div style="padding: 24px;">
		<div class="sr-con">
			<div class="cpr-form">
				<!-- <img class="logo" :src="fullImage(data.apply_logo)" alt="">
        <div class="name">{{ data.apply_name }}</div> -->
				<div class="cpr-form-item">
					<div class="label">续费版本：</div>
					<div class="value">{{ data.type_name }}</div>
				</div>
				<div class="cpr-form-item">
					<div class="label">到期天数：</div>
					<div class="value">{{ Number(data.expire_days) >= 1 ? data.expire_days+'天' : Math.abs(data.expire_days)+'天后到期' }}</div>
				</div>
				<div class="cpr-form-item">
					<div class="label">续费价格：</div>
					<div class="value"><span class="price">{{ data.money }}</span>元</div>
				</div>
				<div class="cpr-form-item">
					<div class="label">续费天数：</div>
					<div class="value">{{ data.days }}天</div>
				</div>
				<div class="cpr-form-item">
					<div class="label">支付方式：</div>
					<div class="cpr-form-method value">
						<div class="pay-method">
							<div class="method method-wx">
								<img class="method-active"
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiMDgzZTc4NS0yZWUwLTk3NDYtODQ2NC1mNDdlYTAyYzc0ZmEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Nzc3M0ZCRTdDMzg5MTFFNzlERkZCQzQ5Q0VGMTcxQkMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Nzc3M0ZCRTZDMzg5MTFFNzlERkZCQzQ5Q0VGMTcxQkMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ZmRiNjU4YjAtMjk3ZC04ZjQ3LTk5OTktNGMyMzgyYTgxZDA0IiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6YzczNmFlNWEtYzM4My0xMWU3LThiMmEtYWEzMDIxNzc2NTJjIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+koZDmgAAActJREFUeNrMmD9Lw0AYh20akNa2OorgopOgbWocdBAcVHDT1U8hODro4Kjil1B0FREXtX/UIuhQJzfdnItdHLT+Tn6BoyRt0rskfeGheZNcefpy997RRGtleaAPwjL6QGIR3JoxS+TBHUjGWZECuBcS4CsukSKogizzhBGTRFmS+I+oRWZBqV0iahGbEjm3h2aEEk+cmK5hRCRR6SQRhYgjke72ohnBEk35eTmsilhsVim/A8IQcTpmOsgg3SIzlBgKOtDQLPEIMj2MzegSmVaQ0FYRUYmaioQOkXyASryDEbHTEm0iQuIhQCXWQYPXSV0iFpuVX4l98CrlezpELLbtnMfza9CS8jewK+VTYEdVxPVQw6iDJbAGXqT72+BXyo9V54hNCbdK1FipMvNDfj6DK+m9TbCqsmpsnrazHs8XwKeUn3GVHEj3RKVOVHbfOXDTQcKJUTAIvplvgA9ej4FTlT5iUyLns3LiKDguzZkG58MFe4hXNI0uB90gEiLmKTMh3TviD+qpsxY5J4Z7WN5C4pLfPQm2/AwyPfpEKWAl2kP0ih+VvabQYYmGGmbb3lH1sTpCCUP6a6Ael4TYs0zO6HNnGcUk0vwTYAAbBUXlSB38aAAAAABJRU5ErkJggg=="
									alt="">
								微信支付
							</div>
						</div>
					</div>
				</div>
				<div class="cpr-form-item">
					<div class="label"></div>
					<div class="value">
						<Button type="primary" @click="renewModal">立即续费</Button>
					</div>
				</div>
			</div>
		</div>
		<Modal v-model="payModal.show" title="支付" @on-cancel="cancelModal()" width="350">
			<div style="text-align: center;width: 100%;font-weight: bold;">
				<div>微信支付</div>
				<vue-qr :text="payModal.code_url" :size="220" style="margin: auto;"></vue-qr>
			</div>
		</Modal>
	</div>
</template>

<script>
	import vueQr from 'vue-qr'
	export default {
		name: '',
		components: {
			vueQr
		},
		data() {
			return {
				store_id: null,
				data: {
					type_name: '',
					price: 0,
					days: 365,
					expire_days: 0
				},
				payModal: {
					show: false,
					code_url: '',
					order_id: '',
					interval: null
				}
			}
		},
		created() {
			if(this.$route.query.store_id){
				this.store_id = this.$route.query.store_id;
				this.init();
			}else{
				this.alertErr('缺少必要的参数');
			}
			
		},
		methods: {
			init() {
				var _this = this;
				this.requestApi('/adm/get_private_site_info',{store_id: this.store_id}).then((res) => {
					if(res.status == 1){
						_this.data = res.data;
					}else{
						_this.alertErr(res.msg);
					}
				})
			},
			// 获取 支付二维码
			renewModal() {
				var _this = this;
				this.requestApi('/adm/get_private_renew_pay',{ data: this.data }).then((res) => {
					if(res.code == 200){
						_this.payModal.show = true;
						_this.payModal.code_url = res.data.code_url;
						_this.payModal.order_id = res.data.order_number;
						_this.ApplyOrder();
					}else{
						this.alertErr(res.msg);
					}
				})
			},
			// 获取订单支付状态
			ApplyOrder() {
				var that = this;
				this.payModal.interval = setInterval(() => {
					that.requestApi('/adm/get_private_site_order',{ order_id: this.payModal.order_id }).then((res) => {
						if(res.status == 1){
							if(res.data.status == 1){
								that.alertSucc('续费成功');
								that.cancelModal();
								setTimeout(()=>{
									that.$router.go(-1);
								},500);
							}
						} else {
							that.alertErr(res.msg);
						}
					})
				},1000);
			},
			cancelModal() {
				this.payModal.show = false;
				this.payModal.code_url = '';
				clearInterval(this.payModal.interval);
				this.payModal.interval = null;
			}
		}
	}
</script>

<style scoped>
	.sr-con {
		background: #fff;
		margin-top: 12px;
		padding: 24px;
	}

	.cpr-form {
		width: 100%;
		height: 100%;
		text-align: center;
		padding: 24px 0;
	}

	.cpr-form .logo {
		width: 80px;
		height: 80px;
		border-radius: 50%;
	}

	.cpr-form .name {
		font-weight: 700;
		font-size: 16px;
		margin-top: 12px;
		margin-bottom: 24px;
	}

	.cpr-form .cpr-form-item {
		width: 60%;
		margin-left: 25%;
		margin-top: 12px;
		display: flex;
		align-items: center;
		min-height: 40px;
		color: #666;
	}

	.cpr-form-item .label {
		width: 30%;
		text-align: right;
	}

	.cpr-form-item .value {
		width: 70%;
		font-size: 16px;
		text-align: left;
	}

	.pay-method {
		display: flex;
	}

	.pay-method .method-wx {
		border: 1px solid #0bc302;
	}

	.pay-method .method {
		width: 160px;
		margin-right: 24px;
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-size: 16px;
		cursor: pointer;
		position: relative;
		border-radius: 2px;
	}

	.pay-method .method img {
		position: absolute;
		left: 125px;
		top: 25px;
	}

	.cpr-form-item .price {
		color: #f03;
		font-weight: 700;
		font-size: 18px;
	}
</style>
